<template>
    <div>
        <WikiSidebar :page-structure="pageStructure" />

        <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button
                type="button"
                class="-m-2.5 p-2.5 text-gray-400 lg:hidden"
                @click="layoutStore.wikiSidebarOpen = true">
                <span class="sr-only">Open sidebar</span>
                <Icon
                    name="heroicons:bars-3"
                    class="h-6 w-6"
                    aria-hidden="true" />
            </button>
            <div class="flex-1 text-sm font-semibold leading-6 text-white">
                {{ currentPageTitle }}
            </div>
        </div>

        <main class="py-10 lg:pl-72">
            <div class="px-4 sm:px-6 lg:px-8">
                <ComingSoonPage
                    v-if="underConstruction"
                    hide-dashboard-link />
                <slot v-else />
            </div>
        </main>
    </div>
</template>

<script setup>
import {useLayoutStore} from "@/store/layout";

const {helpRoute} = useHelpPage()
const layoutStore = useLayoutStore()

const underConstruction = computed(() => useRuntimeConfig()?.public?.underConstruction)

const sidebarOpen = ref(false)
const wikiPages = ref(null)
const pageStructure = ref(null)

const currentPageTitle = computed(() => {
    const defaultTitle = 'Home'
    if(!(wikiPages.value?.length && helpRoute.value)) return defaultTitle
    const helpPage = wikiPages.value.find(page => page.attributes?.url === helpRoute.value)
    return helpPage?.attributes?.title || defaultTitle
})

useStrapi()?.find('wiki-pages', {populate: ['url', 'title', 'children', 'parent']}).then(response => createMenuFromPages(response)).catch(e => console.log(e))

function createMenuFromPages(response) {
    wikiPages.value = response.data
    if (!wikiPages.value?.length) pageStructure.value = []

    pageStructure.value = wikiPages.value.filter(page => !page.attributes?.parent?.data).map(page => ({
        id: page.id,
        title: page.attributes?.title,
        url: page.attributes?.url,
        children: createChildPage(page.id)
    }))
}

function createChildPage(parentId) {
    return wikiPages.value.filter(page => page.attributes?.parent?.data?.id === parentId).map(page => ({
        id: page.id,
        title: page.attributes?.title,
        url: page.attributes?.url,
        children: createChildPage(page.id)
    }))
}

</script>
