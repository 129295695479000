<template>
    <div>
        <TransitionRoot
            as="template"
            :show="layoutStore.wikiSidebarOpen">
            <Dialog
                as="div"
                class="relative z-50 lg:hidden"
                @close="layoutStore.wikiSidebarOpen = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0">
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full">
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0">
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5" />
                            </TransitionChild>
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <div
                                class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                <div class="flex gap-2 h-16 shrink-0 items-center">
                                    <LogoImage class="text-white" />
                                    <h1 class="text-white">
                                        WIKI
                                    </h1>
                                </div>
                                <nav class="flex flex-1 flex-col">
                                    <ul
                                        v-if="pageStructure"
                                        role="list"
                                        class="space-y-1">
                                        <WikiNavItem
                                            :page="{title:'Home', url: ''}"
                                            :current="helpRoute" />
                                        <WikiNavItem
                                            v-for="page in pageStructure"
                                            :key="page.id"
                                            :page="page"
                                            :current="helpRoute" />
                                    </ul>
                                    <LoadingSpinner v-else />
                                </nav>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
                <div class="flex gap-2 h-16 shrink-0 items-center">
                    <LogoImage class="text-white" />
                    <h1 class="text-white">
                        WIKI
                    </h1>
                </div>
                <nav class="flex flex-1 flex-col">
                    <ul
                        v-if="pageStructure"
                        role="list"
                        class="space-y-1">
                        <WikiNavItem
                            :page="{title:'Home', url: ''}"
                            :current="helpRoute" />
                        <WikiNavItem
                            v-for="page in pageStructure"
                            :key="page.id"
                            :page="page"
                            :current="helpRoute" />
                    </ul>
                    <LoadingSpinner v-else />
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useLayoutStore} from "@/store/layout";
import LoadingSpinner from '@/components/global/LoadingSpinner'
import LogoImage from '@/components/global/LogoImage'

defineProps({
    pageStructure: {
        type: Array,
        default: () => null
    }
})

const layoutStore = useLayoutStore()
const {helpRoute} = useHelpPage()
</script>